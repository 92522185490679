import { getPhoneList, showProgress } from "./api";
import Util from "@/utils/utils";
import  SortBar from './components/SortBar'
import  taskList from './components/taskList'
export default {
  components: {
    SortBar,
    taskList
  },
  data() {
    return {
      nolistImg: require("@/assets/IntentionList/none.png"),
      pageLoadFlag: false,
      isLoading:false,
      arg:{},
      sortList: [
        {
          name: "全部",
          code:'',
          sortStatus: 1, //0默认 1降 2升序
        },
        {
          name: "未拓展",
          code:'0',
          sortStatus: 0, //0默认 1降 2升序
        },
        {
          name: "拓展中",
          code:'1',
          sortStatus: 0, //0默认 1降 2升序
        },
        {
          name: "已拓展",
          code:'2',
          sortStatus: 0, //0默认 1降 2升序
        },
      ],
      total:0,
      tableList:[],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/communityCompass/list",
          name: "",
          title: "小区任务列表"
        }
      ],
      totalPages:0,
      totalRows:0,
      filterFrom: {
        pageNo: 1,
        pageSize: 9,
        status:'',
        searchStr:'',
      },

      isCherkYH: false,// 用户类型选中数据需要删除图标
      isShowMoreYH: false,// 用户类型展开收起更多
      isYHLXHaveVal: false,// 用户类型
      cherYHname: "", // 选中的用户类型名称
      filterLoadFlagyh: false,// 用户类型筛选loading
      pageElClickTime:'',
      userTypeList: [
        {
          code:'1',
          name:'weichuli'
        }
      ],// 筛选条件用户类型列表
      // 获客进度
      oneData:{
        scaleSales: 0, //销售任务进度比例
        targetSalesNum: 0, //目标销售金额
        guestNum: 0,	//当前获客数
        salesNum: 0, //当前销售数
        scaleGuest: 0, //获客任务进度比例
        targetGuestNum: 0, //目标获客数
        guestNumXTY:0,
        salesNumXTY:0,
        haveGuide:0
      },
      // //   销售进度
      // twoData:{
      //   currentGrossTotalAmount:0, //本月提货总额
      //   chain:0,// 较上月
      //   year:0,//较去年
      //   completion:'60',//提货完成率
      //   completionjd:60,
      // },
      taskVisible:false,
      taskId:'',
      taskName:''
    };

  },
  mounted() {
    this.getList()
    this.getProcess()
  },
  methods: {
    getProcess(){
      showProgress({}).then(res => {
        if(res.data.code == 0){
          this.oneData = res.data.data
        } else {
          this.oneData = {
            scaleSales: 0, //销售任务进度比例
            targetSalesNum: 0, //目标销售金额
            guestNum: 0,	//当前获客数
            salesNum:0, //当前销售数
            scaleGuest: 0, //获客任务进度比例
            targetGuestNum:0, //目标获客数
            guestNumXTY:0,
            salesNumXTY:0,
            haveGuide:0
          }
        }
        this.$nextTick(()=>{
          this.numoneEcharts()
          this.numTwoEcharts()
        })
      }).catch(error=>{
        this.$nextTick(()=>{
          this.numoneEcharts()
          this.numTwoEcharts()
        })
      })
    },
    // 新增专卖店活动
    createActivity(type,item){
      this.$router.push({
        path: "/creatStoreActivities",
        query: {
          type: type,
          communityName:item.houseName,
          houseId:item.id,
          houseTaskId:''
        }
      });
    },
    openTaskWindow(item) {
      this.taskVisible = true
      this.taskId = item.id
      this.taskName = item.houseName

    },
    numoneEcharts(){
      let myChart = this.$echarts.init(this.$refs.numOne);
      let chartData= {
        value: this.oneData.scaleGuest>=100?100:this.oneData.scaleGuest,
        total: 100
      }
      let value = chartData.value;
      let center = value > 50? ['48.5%', '80%']:['51%', '81%'];
      let max = chartData.total;


      let option = {
        backgroundColor: '#F4F0FF',
        // title: {
        //   text: (value || '-') + '%',
        //   x: 'center',
        //   y: '68%',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: '28',
        //     fontWeight: 'normal'
        //   }
        // },
        angleAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 200,
          startAngle: 180
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true
          },
          data: []
        },
        polar: {
          radius: '255%',
          center: ['50%', '80%']
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: 'bar',
            data: [value],
            // z: 1,
            coordinateSystem: 'polar',
            barMaxWidth: 12,
            name: '',
            roundCap: 1,
            color: '#8F98FF',
            //   new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            //   offset: 0,
            //   color: 'rgba(22,255,241,.1)'
            // },
            //   {
            //     offset: 0.5,
            //     color: 'rgba(22,255,241,.5)'
            //   },
            //   {
            //     offset: 1,
            //     color: 'rgba(22,255,241,1)'
            //   }
            // ]),
            // z: 2
          },
          // 背景图形
          {
            type: 'bar',
            data: [max],
            z: 0,
            silent: true,
            coordinateSystem: 'polar',
            barMaxWidth: 12,
            roundCap: true,
            color: '#E1E1E1',
            barGap: '-100%'
          },
          // 尾端小圆点 饼图
          {
            type: 'pie',
            // 饼图大小跟外层极坐标系相同，需手动调试
            radius: ['113%', '140%'],
            center: center,
            hoverAnimation: false,
            startAngle: 180,
            endAngle: 0,
            silent: 1,
            z: 10,
            data: [
              // 实际值，背景色透明
              {
                name: '',
                value: value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              },
              {
                // 画中间的图标
                value: 0,
                label: {
                  position: 'inside',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: 30,
                  padding:3,
                  // borderWidth: 12,
                  // borderColor: 'rgba(255, 255, 255, 0.4)'
                }
              },
              // 透明填充 angleAxis 的max相同 max : 2
              {
                value: 2 - value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              }
            ]
          }
        ]
      };
      myChart.setOption(option);
    },
    numTwoEcharts(){
      let myChart = this.$echarts.init(this.$refs.numTwo);
      let chartData= {
        value: this.oneData.scaleSales>=100?100:this.oneData.scaleSales,
        total: 100
      }
      let max = chartData.total;
      let value = chartData.value;
      let center = value > 50? ['48.5%', '80%']:['51%', '81%'];
      let option = {
        backgroundColor: '#ECF8F8',
        // title: {
        //   text: (value || '-') + '%',
        //   x: 'center',
        //   y: '68%',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: '28',
        //     fontWeight: 'normal'
        //   }
        // },
        angleAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 200,
          startAngle: 180
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true
          },
          data: []
        },
        polar: {
          radius: '255%',
          center: ['50%', '80%']
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: 'bar',
            data: [value],
            // z: 1,
            coordinateSystem: 'polar',
            barMaxWidth: 12,
            name: '',
            roundCap: 1,
            color: '#18D1BC',
            //   new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            //   offset: 0,
            //   color: 'rgba(22,255,241,.1)'
            // },
            //   {
            //     offset: 0.5,
            //     color: 'rgba(22,255,241,.5)'
            //   },
            //   {
            //     offset: 1,
            //     color: 'rgba(22,255,241,1)'
            //   }
            // ]),
            // z: 2
          },
          // 背景图形
          {
            type: 'bar',
            data: [max],
            z: 0,
            silent: true,
            coordinateSystem: 'polar',
            barMaxWidth: 12,
            roundCap: true,
            color: '#E1E1E1',
            barGap: '-100%'
          },
          // 尾端小圆点 饼图
          {
            type: 'pie',
            // 饼图大小跟外层极坐标系相同，需手动调试
            radius: ['113%', '140%'],
            center: center,
            hoverAnimation: false,
            startAngle: 180,
            endAngle: 0,
            silent: 1,
            z: 10,
            data: [
              // 实际值，背景色透明
              {
                name: '',
                value: value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              },
              {
                // 画中间的图标
                value: 0,
                label: {
                  position: 'inside',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: 30,
                  padding:3,
                  // borderWidth: 12,
                  // borderColor: 'rgba(255, 255, 255, 0.4)'
                }
              },
              // 透明填充 angleAxis 的max相同 max : 2
              {
                value: 2 - value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              }
            ]
          }
        ]
      };
      myChart.setOption(option);
    },
    // 删删除用户类型选项
    delectYHLX() {
      this.filterFrom.status = "";
      this.isShowMoreYH = false;
      this.isYHLXHaveVal = false;
      this.filterFrom.pageNo = 1;
      this.filterFrom.pageSize = 10;
      if(this.isYHLXHaveVal &&this.isHaveVal ) {
        this.leftJl = 256
      } else {
        this.leftJl = 225
      }
      this.getList();
    },
    // 修改用户选项
    changeYH(item) {
      this.filterFrom.status = item.code;
      this.cherYHname = item.name;
      if (this.filterFrom.status) {
        this.isYHLXHaveVal = true;
      } else {
        this.isYHLXHaveVal = false;
      }
      this.isShowMoreYH = false;
      this.filterFrom.pageNo = 1;
      this.filterFrom.pageSize = 10;
      if(this.isYHLXHaveVal  || this.isHaveVal ) {
        this.leftJl = 256
      } else {
        this.leftJl = 225
      }
      this.getList();
    },
    // 展开收起用户类型
    moreYHClick() {
      this.isShowMoreYH = !this.isShowMoreYH;
      if (this.isShowMoreYH) {
        this.isShowMoreZH = false;
      }
      //页面元素点击时间 -》 更多筛选监听后关闭
      this.pageElClickTime = new Date().getTime();
    },
    searchNo(searchStr) {
      this.filterFrom.searchStr = searchStr


    },
    onSearch(searchStr) {
      this.filterFrom.searchStr = searchStr
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: 1
      };
     this.getList()
    },
    sortFun(item) {
      if (item.name == "全部") {
        this.filterFrom.status = "";
      } else{
        this.filterFrom.status = item.code
      }
      //   if (item.name == "进行中") {
      //   this.filterFrom.userTypeId = "进行中";
      // } else if (item.name == "未开始") {
      //   this.filterFrom.userTypeId = "未开始";
      // } else if(item.name == "已结束") {
      //   this.filterFrom.userTypeId = '已结束';
      // }
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: 1
      };
      this.getList()
    },
    // 导出
    searchFun(arg) {
      this.arg = arg[0];
      this.filterFrom.pageNo = 1
      this.getList();
    },
    loadMore() {
      this.filterFrom.pageNo =  this.filterFrom.pageNo + 1,
      this.getList()
    },
    getList(){
      this.pageLoadFlag=true
      let data ={
        pageSize: this.filterFrom.pageSize,
        pageNo:  this.filterFrom.pageNo,
        ...this.filterFrom
      }
      getPhoneList(data).then(res=>{
        if(res.data.code == 0) {
          this.totalRows = res.data.totalRows
          this.totalPages = res.data.totalPages
          this.currentPage =  res.data.currentPage
          if( this.filterFrom.pageNo > 1 && res.data.list.length>0) {
            this.tableList = this.tableList.concat(res.data.list);
          } else {
            this.tableList = res.data.list
          }
          if(res.data.totalPages == 0) {
            this.tableList = []
          }
        } else {
          this.tableList = []
          this.totalPages = 0
          this.$message.warning('查询失败'+res.data.msg)
        }
        this.pageLoadFlag=false
      }).catch(error=>{
        this.pageLoadFlag=false
      })
    }
  },


};
