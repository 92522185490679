import http from "@/utils/request";
// 列表查询
export function getPhoneList(data) {
  return http({
    method: "post",
    url: "/compassHouse/list.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}
export function getInfo(data) {
  return http({
    method: "post",
    url: "/compassHouse/info.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}

export function showProgress(data) {
  return http({
    method: "get",
    url: "/compassHouseTask/showProgress.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// export function getExport(data) {
//   return http({
//     url: '/fast/potential/shopPotentialShortMessage/export.nd',
//     method: 'post',
//     // contentType: "application/json;charset=UTF-8",
//     // type: 'stringfy',
//     data:data,
//     responseType: 'blob'
//   })
// }