
<template>
  <div class="sort-bar-box clearfix">
    <ul>
      <li
        @click="sortFun(item, index)"
        v-for="(item, index) in data"
        :key="index"
        class="d-f-c"
        :class="
          item.sortStatus == 0 ? '' : item.sortStatus == 1 ? 'sort' : 'resort'
        "
      >
        <span>{{ item.name }}</span>
      </li>
    </ul>
    <div class="total-box">共搜索到 {{ total }} 条数据</div>
    <a-input
      class="addBtn "
      placeholder="请输入小区名称/任务名称"
      v-model="searchValue"
      @keydown.enter="onSearch"
      @change="onSearchNo"
    >
      <i class="iconfont icon-shouye-sousuo" slot="suffix" @click="onSearch"/>
    </a-input>
  </div>
</template>

<script>
export default {
  name: "SortBar",
  data() {
    return {
      currentProvide: undefined,
      cartInfoTip:'',
      searchValue:''
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    total: {
      type: [String, Number],
      default: "0"
    },

  },
  mounted() {
    this.getMessage()
  },
  methods: {
    onSearchNo() {
      this.$emit('searchNo',this.searchValue);
    },
    onSearch() {
      this.$emit('search',this.searchValue);
    },
    getMessage() {
      const msg =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14187495683'
      })
      this.cartInfoTip = msg.msgDesc
    },
    sortFun(item, idx) {
      //需要emit
      for (let key in this.data) {
        if (key == idx) {
          continue;
        } else {
          this.data[key].sortStatus = 0;
        }
      }
      if (item.sortStatus == 0) {
        if (item.name == "全部") {
          item.sortStatus = 1;
        } else if (item.name == "未拓展") {
          item.sortStatus = 1;
        } else if (item.name == "拓展中") {
          item.sortStatus = 1;
        } else if (item.name == "已拓展") {
          item.sortStatus = 1;
        }
      } else if (item.sortStatus == 1) {
        item.sortStatus = 2;
      } else {
        item.sortStatus = 1;
      }
      this.$emit("click", item);
    },
    gotoAddPage(){
      this.$emit("gotoAddPage", );
    }
  }
};
</script>

<style lang="less" scoped>
.sort-bar-box {
  position: relative;
  width: 1188px;
  height: 54px;
  margin: 0 auto;
  background-color: #fff;
  // padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ul {
    float: left;
    padding-right: 24px;
    li {
      height: 54px;
      line-height: 54px;
      text-align: center;
      //padding: 0 6px;
      margin-left: 32px;
      float: left;
      cursor: pointer;
      transition: all 0.1s;
      &.sort {
        color: #00aaa6;
        .iconfont {
          visibility: visible;
          transform: rotate(180deg);
          color: #00aaa6;
        }
      }
      &.resort {
        color: #00aaa6;
        .iconfont {
          visibility: visible;
          color: #00aaa6;
        }
      }
      &:hover {
        color: #00aaa6;
        .iconfont {
          color: #00aaa6;
        }
      }
      span {
        display: inline;
      }
      .iconfont {
        font-size: 8px;
        margin-left: 6px;
        color: #777;
        visibility: hidden;
      }
    }
    li:nth-child(2){
      margin-left: 32px;
    }
    li:first-child{
      margin-left: 24px;
    }
  }
  .total-box {
    font-size: 14px;
    color: #777777;
    height: 18px;
    line-height: 18px;
    border-left: 1px solid #eee;
    text-indent: 23px;
    float: left;
    // margin-top: 11px;
  }
  .addBtn {
    width: 270px;
    height: 32px;
    border-radius: 4px;
    position: absolute;
    right: 24px;
    .icon-shouye-sousuo{
      color: #aaa;
    }
    .icon-shouye-sousuo:hover{
      color: #00aaa6;
    }
  }
  .freeText {
    background: #ffffff;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.13);
    border-radius: 2px;
    float: left;
    margin-left: 350px;
    .text {
      padding: 6px 12px 6px 12px;
      font-size: 12px;
      font-weight: 400;
      color: #ff8f3c;
    }
  }
}
</style>
