<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNameTack"
      :width="650"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >
      <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
      <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>小区拓展任务
        </span>
      </div>

      <div class="contentBox">
        <p class="title">{{taskName}}</p>
        <div class="tableForm" v-if="listData.length>0">
          <div class="everyBox"
               v-for="(item,index) in listData"
               :key="index"
          >
            <p class="topTxtBox">
              <span class="communityName">
                {{item.taskName}}
                <span v-if="item.isSpecialTask" class="tag">商家创建</span>
              </span>
              <span class="down" @click="downFile(item)">
                下载方案
              </span>
            </p>
            <div class="bottomBox">
              <div class="dataBox">
                <div class="taskTile">
                  <div>
                    <p class="tips1">获客任务</p>
                    <p class="num"><span class="numRed">{{Number(item.finishGuestNum)+Number(item.guestNumXTY)}}</span>/{{item.targetGuestNum || 0 }}(人)</p>
                  </div>
                  <p class="tips" @click="gotoUserList(item)">获客详情</p>
                </div>
                <p class="dg" v-if="item.haveGuide==1">导购({{ item.guestNumXTY || 0 }})<span>商家({{item.finishGuestNum || 0}})</span></p>
                <div class="oneEchartsBox">
                  <div class="oneEcharts" :id="'oneEcharts'+index" :ref="'oneEcharts'+index"></div>
                </div>
                <p class="time">活动时间：<span v-if="item.beginTime||item.endTime">{{item.beginTime}}~{{item.endTime}}</span>  </p>
                <p class="process">创建时间：{{item.createdDate}}</p>
              </div>
              <div class="dataBox">
                <div class="taskTile">
                  <div>
                    <p class="tips1">销售任务</p>
                    <p class="num"><span class="numRed">{{(Number(item.finishSalesNum)+Number(item.salesNumXTY)).toFixed(2) }}</span>/{{item.targetSalesNum || 0}}(万元)</p>
                  </div>
                 <p class="tips" @click="gotoSaleList(item)">销售详情</p>
                </div>
                <p class="dg" v-if="item.haveGuide==1">导购({{item.salesNumXTY || 0}})<span>商家({{item.finishSalesNum || 0}})</span></p>
                <div  class="twoEchartsBox">
                  <div class="twoEcharts" :id="'twoEcharts'+index" :ref="'twoEcharts'+index"></div>
                </div>
                <p class="time">创建人：{{item.creator}}</p>
                <p class="process">审批流程：{{item.checkStatus}}</p>
              </div>
            </div>


          </div>

        </div>
        <div class="noneBox" v-if="listData.length<=0">
          <img :src="nolistImg" alt="">
          <p class="none">暂无小区拓展任务~</p>
        </div>
      </div>
    </a-modal>

  </div>

</template>

<script>

import { getInfo } from "@/views/communityCompass/list/api";

export default {
  components:{
  },
  name: "taskList",
  data() {
    return {
      nolistImg: require("@/assets/order/none.png"),
      listData:[]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: Number,
    },
    taskName: {
      type: String,
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
        if(newValue){
          this.getDetail()

        }else{
          this.taskId=''
          this.taskName=''
          this.listData = []

        }
      }
    },

  },
  created() {
    // if(this.listData.length>0){
    //   this.$nextTick(()=>{
    //     for(const key in this.listData) {
    //       this.drawChartsone(key,this.listData[key])
    //       this.drawChartsTwo(key,this.listData[key])
    //     }
    //   })
    // }


  },
  methods: {
    downFile(item) {
      if(item.files.length>0){
        const eleLink = document.createElement('a')
        eleLink.download = item.files[0].fileName
        eleLink.href = item.files[0].fileUrl
        eleLink.click()
        eleLink.remove()
      } else {
        this.$message.warning('暂时没有下载内容')
      }

    },
    // 获取小区详情
    getDetail(){
      let data = {
        id:this.taskId
      }
      getInfo(data).then(res=>{
        this.listData = res.data.data.taskList
        if(this.listData.length>0){
          this.listData.forEach(el => {
            el.beginTime = el.beginTime?el.beginTime.slice(0, 10):''
            el.endTime = el.endTime?el.endTime.slice(0, 10):''
          })
          this.$nextTick(()=>{
            for(const key in this.listData) {
              this.drawChartsone(key,this.listData[key])
              this.drawChartsTwo(key,this.listData[key])
            }
          })
        }
      })
    },
    // 用户列表
    gotoUserList(item){
      this.$router.push({
        path: "/IntentionList",
        query: {
          id: item.id,
          // type: 'view',
          // "community:" + this.taskName +','+
          filter: 'taskName:' + item.taskName, //
          onlyUserParam: true
        }
      });
    },
    // 销售订单审核出库页面
    gotoSaleList(item){
      this.$router.push({
        path: "/channelOrder/orderList",
        query: {
          id: item.id,
          filter: "taskName:" + item.taskName, // 小区名称
          // taskName:item.taskName, // 任务名称 暂时后端没有参数
          onlyUserParam: true
        }
      });

    },
    // 第一个div里面的图表
    drawChartsone(key,data) {
      var myChart = this.$echarts.init(document.getElementById("oneEcharts" + key));
      this.$forceUpdate()
      data.guestNumRate = data.guestNumRate&&data.guestNumRate > 0  ? data.guestNumRate : 0
      let value= data.guestNumRate>100?100:data.guestNumRate
      let max = data.guestNum;
      let offset =value<15?[16,0]:[-26,0]
      let option={
        // backgroundColor:"#EEF9F9",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:40,
              height:18,
              position:'right',
              offset:offset,
              borderWidth:1,
              borderColor:'#18D1BC',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return param.value + '%';
              },
              textStyle:{
                color: '#00AAA6',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 10,
                color: '#18D1BC'
              },

            },
            barWidth: 10,
            data: [value]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 10,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    // 第一个div里面的图表
    drawChartsTwo(key,data) {
      // var myChart = this.$echarts.init(this.$refs.twoEcharts);
      var myChart = this.$echarts.init(document.getElementById("twoEcharts" + key));
      this.$forceUpdate()
      data.salesNumRate = data.salesNumRate&&data.salesNumRate > 0  ? data.salesNumRate : 0
      let value= data.salesNumRate>100?100:data.salesNumRate
      let max = data.salesNum;
      let offset =value<15?[16,0]:[-26,0]
      let option={
        // backgroundColor:"#FBFBFB",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:40,
              height:18,
              position:'right',
              offset:offset,
              borderWidth:1,
              borderColor:'#18D1BC',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return param.value + '%';
              },
              textStyle:{
                color: '#00AAA6',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 10,
                color: '#18D1BC'
              },

            },
            barWidth: 10,
            data: [value]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 10,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    handleOk() {
      this.$emit("update:visible", false);
    },
    cancle() {
      this.$emit("update:visible", false);
    },
  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNameTack {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding:30px 24px;

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 28px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .contentBox {
        height: 538px;
        overflow-y: auto;

        .title{
          text-align:left;
          font-size: 16px;
          font-weight: 500;
          color: #262626;
          line-height: 22px;
          margin-bottom: 12px;
        }
        .tableForm {
          width: 602px;
          .everyBox {
            width: 602px;
            background: linear-gradient(90deg, #EEF9F9 0%, #FBFBFB 100%);
            border-radius: 8px;
            margin-bottom: 12px;
            padding: 14px 16px;
            .name {
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
              height: 20px;
            }
            .topTxtBox {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .communityName{
                color: #262626;
                font-size: 14px;
                .tag  {
                  font-size: 12px;
                  font-weight: 400;
                  color: #FF8F3C;
                  line-height: 16px;
                  padding:0 4px;
                  height: 18px;
                  border-radius: 2px;
                  border: 1px solid #FF8F3C;
                  text-align: center;
                  margin-left:4px;

                }
              }
              .down{
                font-size: 12px;
                font-weight: 400;
                color: #1890FF;
                cursor: pointer;
              }
            }
            .bottomBox {
              display: flex;
              flex-direction: row;
               .dataBox{
                 .taskTile {
                   display: flex;
                   flex-direction: row;
                   align-items:flex-end;
                   margin-bottom:8px;
                   margin-top:12px;
                   justify-content: space-between;
                   .tips {
                     font-size: 12px;
                     font-weight: 400;
                     color: #1890FF;
                     //margin-right: 8px;
                     //text-decoration: underline;
                     cursor: pointer;
                   }
                   div {
                     display: flex;
                     flex-direction: row;
                     align-items:flex-end;
                     justify-content: flex-start;
                     .tips1{
                       font-size: 12px;
                       color: #262626;
                       margin-right: 8px;
                     }
                     .num {
                       font-size: 12px;
                       font-weight: 500;
                       color: #262626;
                       height: 20px;
                       line-height: 20px;
                       .numRed {
                         font-size: 20px;
                         font-weight: 500;
                         color: #FC5260;
                         height: 20px;
                         line-height: 20px;
                       }
                     }
                   }
                 }
                 .dg{
                   font-size: 12px;
                   width: 100%;
                   text-align: left;
                   margin-left: 56px;
                   span{
                     margin-left: 10px;
                   }
                 }
                 .oneEchartsBox{
                   .oneEcharts{
                     width: 248px;
                     min-height: 20px;
                     position: relative;
                     //top: 60px;
                     //left: 24px;
                     //background: #E1E1E1;
                     //border-radius: 7px;
                   }
                 }

                 .twoEchartsBox{
                   .twoEcharts{
                     width: 248px;
                     min-height: 20px;
                     position: relative;
                     //top: 60px;
                     //left: 24px;
                     //background: #E1E1E1;
                     //border-radius: 7px;
                   }
                 }

                 .time, .process{
                   font-size: 12px;
                   font-weight: 400;
                   color: #777777;
                   line-height: 17px;
                   text-align: left;
                   margin-top:12px;
                 }
                 .process{
                   margin-top:4px;
                 }
               }
              .dataBox:last-child{
                margin-left: 58px;
              }
            }

          }
          .everyBox:last-child{
            margin-bottom: 0;
          }

        }
        .noneBox{
          display:flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 600px;
          height: 253px;
          .none {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
          }
          img{
            width: 150px;
            height: 121px;
          }
        }
      }

    }


    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:8px 0 18px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


